<template>
    <div
        class="table-filter-container"
        :class="{ disabled }"
        @click="
            this.disabled ? '' : (isShowModal = true),
                this.$setGaEvent('clickFilter', 'click-TableTool')
        "
    >
        <span v-text="'快速搜尋'" />
        <span class="dark-filter-icon" :class="{ 'red-dot': isFilterSet }" />
    </div>
    <FilterModal
        v-model="isShowModal"
        v-model:checkedList="filterCheckedListValue"
        v-model:filterColumnKey="filterColumnKeyValue"
        :isFilterSetList="isFilterSetList"
        :columns="filterShowColumns"
        :rows="rows"
    />
</template>

<script>
// import _ from 'lodash'
import FilterModal from '@/components/table/FilterModal.vue'

export default {
    name: 'TableFilter',
    components: {
        FilterModal
    },
    inheritAttrs: false,
    emits: [
        'update:modelValue',
        'update:filterCheckedList',
        'update:filterColumnKey'
    ],
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        filterColumnKey: {
            type: String,
            default: ''
        },
        filterCheckedList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isFilterSetList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        columns: {
            type: Array,
            default: function () {
                return []
            }
        },
        rows: {
            type: Array,
            default: function () {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        filterShowColumns: function () {
            return this.columns.filter((column) => column.isFilterable)
        },
        filterCheckedListValue: {
            get() {
                return this.filterCheckedList
            },
            set(val) {
                this.$emit('update:filterCheckedList', val)
            }
        },
        filterColumnKeyValue: {
            get() {
                return this.filterColumnKey
            },
            set(val) {
                this.$emit('update:filterColumnKey', val)
            }
        },
        isFilterSet: function () {
            return Object.values(this.isFilterSetList).some((isSet) => isSet)
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.table-filter-container {
    display: flex;
    align-items: center;
    color: $secondary-grey;
    font-size: 14px;
    cursor: pointer;
}

.sort-label {
    margin-right: 5px;
}

.dark-filter-icon {
    margin-left: 3px;
    height: 12px;
    width: 12px;
    &.red-dot:after {
        content: ' ';
        display: block;
        margin: -4px 0 0 16px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $primary-red;
    }
}
</style>
